import CryptoJS from "crypto-js"
export function manyStr(who){
  const defaultKeyStr = CryptoJS.enc.Utf8.parse('NaolOfwUCHsrL3Ho');
  const defaultIvStr = CryptoJS.enc.Utf8.parse('8351935195510518');
  let decryptData = CryptoJS.AES.decrypt(who, defaultKeyStr, {
    iv: defaultIvStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let strNew = JSON.parse(CryptoJS.enc.Utf8.stringify(decryptData))
  console.log(strNew)
  let newArray = [strNew.data.SecurityToken,strNew.data.AccessKeyId,strNew.data.AccessKeySecret]
  return newArray
}


