import request from '@/utils/request'

//获取回执单数据
export function GetReceiptList(params) {
  return request({
      url: `/api/qyg-official-receipt/order-receipt-info`,
      method: 'post',
      data: params
  })
}

//alliyun 
export function AliStsToken(params) {
  return request({
      url: `/api/upload/ali-sts-token`,
      method: 'post',
      data: params
  })
}